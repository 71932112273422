<template>
  <v-container
    fluid
    class="pa-0 ma-0 white"
  >
    <v-row
      no-gutters
      align="center"
      class="page pa-0 ma-0"
    >
      <v-col
        cols="12"
        sm="5"
        md="8"
        class="d-none d-sm-flex"
        align-self="center"
      >
        <v-img
          src="@/assets/img/lar-ismael-voluntarios.jpeg"
          height="100vh"
        />
      </v-col>
      <v-col
        cols="12"
        sm="7"
        md="4"
      >
        <div class="d-flex flex-column justify-space-between align-center">
          <v-img
            src="@/assets/img/logo.jpeg"
            width="150px"
            class="align-center justify-center"
          />
        </div>
        <div class="d-flex flex-column justify-space-between align-center">
          <h4>Lar Ismael</h4>
          <!-- <h5>Cadastro para o sorteio de entrega de sacolinhas</h5> -->
        </div>
        <login-form />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    components: {
      LoginForm: () => import('../../components/admin/LoginForm.vue'),
    }
  }
</script>
<style scoped>
  .page {
    /* background-image: require('../../assets/img/fundo1.jpg'); */
    height: 100vh;
  }
</style>
